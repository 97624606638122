import React from 'react';
import './styles.scss';
import Fade from 'react-reveal/Fade';
import ReactTooltip from 'react-tooltip';
import StartModal from '../../StartModal';
import { getUtmParams } from '../../../../util/getUtmFromUrl';

const check = 'https://sw-assets.gumlet.io/trivia/pricing/green-tick.svg';
const cross = 'https://sw-assets.gumlet.io/trivia/pricing/red-cross.svg';

class PriceTable extends React.Component {
    state = {
        showModal: false,
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    handleStartToday = () => {
        this.setState({ showModal: true });
    };

    render() {
        return (
            <div className='pricingBreakDownWrap'>
                <div className='whiteBar' />
                <div className='taPriceWrapper PriceTableWrap'>
                    <Fade top cascade>
                        <div className='pricingBreakDownHeader'>
                            <span>Breakdown of Features</span>
                            <span>Basic</span>
                            <span>Standard</span>
                            <span>Pro</span>
                            <span>Enterprise</span>
                        </div>
                    </Fade>

                    <div className='pricingBreakDownSubHeader'>
                        <h6>Activities</h6>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p data-tip data-for='instantGames'>
                                    Instant Games
                                </p>
                                <ReactTooltip
                                    className='tooltip'
                                    id='instantGames'
                                    place='top'
                                    effect='solid'
                                    arrowColor='#C9C9FA'
                                >
                                    Instant Quizzes, GIF Charades, True or False, Water Cooler, (Un)Popular Opinion
                                    Polls, Customizable Polls, Pictionary, Gotcha!
                                </ReactTooltip>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Customizable Quizzes</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownSubHeader'>
                        <h6>Participants</h6>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>All Games</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>5 per Game</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>VirtualCoffee (Slack)</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>
                                    3 VirtualCoffees pairings
                                    <br />
                                    (6 participants) per pairing cycle
                                </p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <p>Unlimited</p>
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownSubHeader'>
                        <h6>Data & Reporting</h6>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Results History</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Real-time Results</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Automated Results Sharing</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>
                                    Advanced Analytics
                                    <span>New</span>
                                </p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownSubHeader'>
                        <h6>Controls & Permissions</h6>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Dashboard</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Admin controls</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingBreakDownRowWrap'>
                        <Fade top cascade>
                            <div className='pricingBreakDownRow'>
                                <p>Notification Controls</p>
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={cross} data-src={cross} alt={'not available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                            <div className='pricingBreakDownRow'>
                                <img src={check} data-src={check} alt={'available'} />
                            </div>
                        </Fade>
                    </div>

                    <div className='pricingCompare' hidden={!this.state.expandFeature}>
                        <div className='pricingBreakDownSubHeader'>
                            <h6>Security</h6>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Cloud Hosting</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Dashboard</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>SSL & Encryption</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Single sign-on</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Custom Data Retention Policy</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Activity Reports</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownSubHeader'>
                            <h6>Support</h6>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Knowledge Base</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Email & chat support</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Higher Uptime SLA</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Dedicated Account Manager</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>

                        <div className='pricingBreakDownRowWrap'>
                            <Fade top cascade>
                                <div className='pricingBreakDownRow'>
                                    <p>Priority Customer Support</p>
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={cross} data-src={cross} alt={'not available'} />
                                </div>
                                <div className='pricingBreakDownRow'>
                                    <img src={check} data-src={check} alt={'available'} />
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <StartModal
                    show={this.state.showModal}
                    hide={() => this.setState({ showModal: false })}
                    utm={this.state.utm}
                    slackAddUrl={process.env.GATSBY_TRIVIA_SLACK_ADD_URL}
                    teamsAddUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_URL}
                    slackLoginUrl={process.env.GATSBY_TRIVIA_SLACK_LOGIN_URL}
                    teamsLoginUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_LOGIN_URL}
                />

                <div
                    role={'button'}
                    className='seeAllFeatures'
                    onClick={() => this.setState({ expandFeature: !this.state.expandFeature })}
                    onKeyDown={(e) =>
                        e.keyCode === 13 ? this.setState({ expandFeature: !this.state.expandFeature }) : null
                    }
                    tabIndex={0}
                >
                    <div className='pricingBreakDownRowWrap pricingBreakDownButton'>
                        <div className='pricingBreakDownRow'>
                            <p />
                        </div>
                        <div className='pricingBreakDownRow'>
                            <p>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </p>
                        </div>
                        <div className='pricingBreakDownRow'>
                            <p>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </p>
                        </div>
                        <div className='pricingBreakDownRow'>
                            <p>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </p>
                        </div>
                        <div className='pricingBreakDownRow'>
                            <p>
                                <div className='taPriceButton'>
                                    <button onClick={() => {}}>Contact Us</button>
                                </div>
                            </p>
                        </div>
                    </div>
                    <span>
                        Show Full Feature Comparison &nbsp;
                        <img
                            className={this.state.expandFeature ? '' : 'rotateArrow'}
                            src={'https://sw-assets.gumlet.io/trivia/pricing/purple-up-arrow.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/pricing/purple-up-arrow.svg'}
                            alt={'more'}
                        />
                    </span>
                </div>
            </div>
        );
    }
}

export default PriceTable;
