import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import './styles.scss';

class CustomAccordion extends React.Component {
    state = { isClicked: `100` };

    render() {
        const { data = [] } = this.props;
        return (
            <div className={'faq-wrapper'}>
                <Accordion className='faq-accordion'>
                    {data.map((d, index) => (
                        <Card key={index}>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant='link'
                                    eventKey={`${index}`}
                                    onClick={() => {
                                        this.setState({
                                            isClicked: this.state.isClicked === `${index}` ? `100` : `${index}`,
                                        });
                                    }}
                                >
                                    {d.question}
                                    <img
                                        src={
                                            this.state.isClicked === `${index}`
                                                ? 'https://sw-assets.gumlet.io/trivia/faq/purple-minus.svg'
                                                : 'https://sw-assets.gumlet.io/trivia/faq/purple-plus.svg'
                                        }
                                        data-src={
                                            this.state.isClicked === `${index}`
                                                ? 'https://sw-assets.gumlet.io/trivia/faq/purple-minus.svg'
                                                : 'https://sw-assets.gumlet.io/trivia/faq/purple-plus.svg'
                                        }
                                        alt={''}
                                    />
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={`${index}`}>
                                <Card.Body>{d.answer}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </div>
        );
    }
}

export default CustomAccordion;
