const getStepData = (step) => {
    step = parseInt(step);
    switch (step) {
        case 0:
            return { standard: '$29', pro: '$49', users: '1 - 25 Active Users' };
        case 1:
            return { standard: '$49', pro: '$79', users: '26 - 50 Active Users' };
        case 2:
            return { standard: '$99', pro: '$129', users: '51 - 100 Active Users' };
        case 3:
            return { standard: '$149', pro: '$199', users: '101 - 250 Active Users' };
        case 4:
            return { standard: '$249', pro: '$349', users: '251 - 499 Active Users' };
        default:
            return { standard: 'Contact us', pro: 'Contact us', users: '500+ Active Users' };
    }
};

export default getStepData;
