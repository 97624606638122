import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Testimonials from '../../../components/trivia/TestimonialsOld';
import Footer from '../../../components/trivia/Footer';
import WorkSpace from '../../../components/trivia/WorkSpace';
import '../slack/styles.scss';
import './styles.scss';
import Price from '../../../components/trivia/pricing/Price';
import Client from '../../../components/springworks/OurClients';
import { Helmet } from 'react-helmet';
import FAQAccordion from '../../../components/trivia/Accordion';
import PriceTable from '../../../components/trivia/pricing/PriceTable';
import getPricing from './_data';

class Pricing extends React.Component {
    state = {
        range: 0,
    };

    askQuestion = () => {
        // if (typeof window !== `undefined` && window !== undefined) {
        //     window.Tawk_API.toggle();
        // }
    };

    render() {
        const pricingData = getPricing(this.state.range);
        return (
            <>
                <Helmet>
                    <title>Pricing | Trivia</title>
                    <meta
                        name='description'
                        content='Trivia’s Basic Plan is free-forever for companies of all sizes. Upgrade to a premium plan for more features any time. Start with a 14-day PRO trial for free now!'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Pricing | Trivia' />
                    <meta
                        property='og:description'
                        content='Trivia’s Basic Plan is free-forever for companies of all sizes. Upgrade to a premium plan for more features any time. Start with a 14-day PRO trial for free now!'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/pricing/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/TriviaPricing.jpg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaPricing.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Pricing | Trivia' />
                    <meta
                        property='twitter:description'
                        content='Trivia’s Basic Plan is free-forever for companies of all sizes. Upgrade to a premium plan for more features any time. Start with a 14-day PRO trial for free now!'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaPricing.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/pricing/' />
                </Helmet>
                <div className='pageWrap pricingWrap'>
                    <Navbar />

                    <div className='slackHeader pricingHeader'>
                        <h1>Foster engagements. Bolster happy teams.</h1>
                        <p>All plans include a 14-day Pro trial.</p>

                        <div className='pricingConditions'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pricing/free.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pricing/free.svg'}
                                    alt={'free plan'}
                                />
                                <div>Free forever</div>
                            </span>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pricing/period.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pricing/period.svg'}
                                    alt={'secure payment with stripe'}
                                />
                                <div>Secured with Stripe</div>
                            </span>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pricing/no-credit.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pricing/no-credit.svg'}
                                    alt={'no credit card required'}
                                />
                                <div>No credit card required</div>
                            </span>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/pricing/cancel.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/pricing/cancel.svg'}
                                    alt={'cancel anytime'}
                                />
                                <div>Cancel anytime</div>
                            </span>
                        </div>
                    </div>

                    <div className='pricingParticulars'>
                        <Price standard={pricingData.standard} pro={pricingData.pro} />
                    </div>

                    <div className='priceTableParticulars'>
                        <PriceTable />
                    </div>

                    <div className='SlackTestimonials'>
                        <Testimonials />
                    </div>

                    <div className='SlackClient'>
                        <h6>Trusted by the world’s fastest growing companies including</h6>
                        <Client />
                    </div>

                    <div className='pricingFAQWrap'>
                        <div className='pricingFAQHeader'>
                            <h2>FAQ’s</h2>
                        </div>
                        <FAQAccordion
                            data={[
                                {
                                    question: 'What are Active Users? How much do I have to pay for my team?',
                                    answer: (
                                        <>
                                            You are billed based on the number of Active Users in your organisation.
                                            <br />
                                            <br />
                                            A User is considered Active if they interact with Trivia - submit a Trivia
                                            command, send a message to the app, start a game, schedule a game,
                                            participate in a game, engage in a Water Cooler conversation, view the
                                            Leaderboard, log in to the Dashboard, etc.
                                            <br />
                                            <br />
                                            The count for the number of Active Users is updated daily. To view your
                                            billing amount based on the number of Active Users at that point in time,
                                            visit the Payment tab in your Trivia Dashboard{' '}
                                            <a
                                                href='https://trivia.springworks.in/payment/'
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                here.
                                            </a>{' '}
                                            (Do note that only admins have access to the Payment tab.)
                                            <br />
                                            <br />
                                            At the end of each billing cycle, we generate an invoice based on the number
                                            of Active Users in your organisation.
                                        </>
                                    ),
                                },
                                {
                                    question: 'Am I eligible for discounts?',
                                    answer: (
                                        <>
                                            Yes. We’re dedicated to making your workspace fun and we’re committed to it.
                                            If its your first ever subscription with Trivia, you can avail exciting
                                            offers by contacting us, We’re always here to help you get the best deal.
                                            We’ve also got special offers for NGOs and non-profit. Kindly get in touch
                                            with our support to know further.
                                        </>
                                    ),
                                },
                                {
                                    question: 'How does the 14-day trial period work?',
                                    answer: (
                                        <>
                                            After you sign up, you can access all the PRO features in Trivia for 14
                                            days. We want to make sure that you and your teams are completely satisfied
                                            with Trivia before you subscribe. At any point during your trial, you can
                                            add your credit card to purchase any plan of your choice.
                                        </>
                                    ),
                                },
                                {
                                    question: 'Do you have any cancellation fees?',
                                    answer: (
                                        <>
                                            No. Trivia is a pay-as-you-go service. You can cancel whenever you want but
                                            your cancellation will come into effect only after the end of your on-going
                                            term.
                                        </>
                                    ),
                                },
                                {
                                    question: ' What are the different payment modes?',
                                    answer: <>We accept payment via Visa, MasterCard, American Express, and PayPal.</>,
                                },
                                {
                                    question: 'What if I pay annually?',
                                    answer: (
                                        <>
                                            Yes, you can. By opting for annual plans you’re eligible for exciting offers
                                            and discounts. Please contact our support team to get started right away.
                                        </>
                                    ),
                                },
                                {
                                    question: 'Is my data safe?',
                                    answer: (
                                        <>
                                            We take security seriously and ensure that our application is always up to
                                            date with the latest security patches, for FREE, to keep your data safe.
                                        </>
                                    ),
                                },
                            ]}
                        />
                        <div className='taFAQFind'>
                            <div className='FAQOuterWrap'>
                                <div className='taFAQFindContent'>
                                    <div className='taFAQFindContentInner'>
                                        <h2>Can’t find an answer to your question?</h2>
                                        <p>Ask us anything, we are here to help you to deal with any thing</p>
                                        <button onClick={this.askQuestion}>Ask question</button>
                                    </div>
                                    {/* <div className='taFAQFCRight'>
                                        <img
                                            className='taFAQFindContent-illustration'
                                            alt={''}
                                            data-src={'https://sw-assets.gumlet.io/trivia/faq/mob-question.png'}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <WorkSpace />
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
export default Pricing;
