import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './styles.scss';
import ReactTooltip from 'react-tooltip';
import StartModal from '../../StartModal';
import { getUtmParams } from '../../../../util/getUtmFromUrl';

const check = 'https://sw-assets.gumlet.io/trivia/pricing/check-icon.svg';

class Price extends React.Component {
    state = {
        showModal: false,
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    handleStartToday = () => {
        this.setState({ showModal: true });
    };

    render() {
        return (
            <div className='priceCtr'>
                <div className='taPriceWrapper'>
                    <div className='taPriceHeader'>
                        <div className='taHeader'>
                            <h3>Start your free 14-day trial with Trivia today</h3>
                        </div>
                    </div>

                    <div className='taPriceCardWrapper'>
                        {/* Basic */}
                        <div className='taPriceCard'>
                            <div className='taPriceType'>
                                <h5>Basic</h5>
                                <h2>
                                    $0
                                    <span>
                                        <span data-tip data-for='userTooltip'>
                                            /user
                                        </span>
                                        /month
                                    </span>
                                </h2>
                            </div>
                            <div className='taPriceDecription'>
                                <div className='taPriceSummary'>
                                    <div className='taPriceSummaryContext'>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Unlimited
                                            <div
                                                className='instantGames'
                                                data-tip=' Instant Quizzes, GIF Charades, True or False, Water Cooler, (Un)Popular
                                                Opinion Polls, Customizable Polls, Pictionary, Gotcha!'
                                                data-for='instantGames'
                                            >
                                                Instant games
                                            </div>
                                            <ReactTooltip
                                                className='tooltip'
                                                id='instantGames'
                                                place='top'
                                                effect='solid'
                                                arrowColor='#C9C9FA'
                                            />
                                        </p>

                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Automations
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            5 participants per game
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            3 VirtualCoffee pairings (6 participants) per pairing cycle
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='taPriceButton'>
                                <button onClick={this.handleStartToday}>Add Trivia</button>
                            </div>
                        </div>

                        {/* Standard */}
                        <div className='taPriceCard'>
                            <div className='taPriceType'>
                                <span className='populer'>Popular</span>
                                <h5>Standard </h5>
                                {this.props.standard === 'Contact us' ? (
                                    <h3>Contact us</h3>
                                ) : (
                                    <h2>
                                        $4
                                        <span>
                                            <span data-tip data-for='userTooltip'>
                                                /user
                                            </span>
                                            /month
                                        </span>
                                    </h2>
                                )}
                            </div>
                            <div className='taPriceDecription'>
                                <div className='taPriceSummary'>
                                    <div className='taPriceSummaryContext'>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            <b>Everything in Basic +</b>
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Unlimited participants per game
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Unlimited VirtualCoffee pairings
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Admin controls
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Organization-wide Leaderboard
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Notification controls
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='taPriceButton'>
                                <button onClick={this.handleStartToday}>Add Trivia</button>
                            </div>
                        </div>

                        {/* Pro */}
                        <div className='taPriceCard'>
                            <div className='taPriceType'>
                                <h5>Pro</h5>
                                {this.props.pro === 'Contact us' ? (
                                    <h3>Contact us</h3>
                                ) : (
                                    <h2>
                                        $6
                                        <span>
                                            <span data-tip data-for='userTooltip'>
                                                /user
                                            </span>
                                            /month
                                        </span>
                                    </h2>
                                )}
                            </div>
                            <div className='taPriceDecription'>
                                <div className='taPriceSummary'>
                                    <div className='taPriceSummaryContext'>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            <b>Everything in Standard +</b>
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Customizable Quizzes
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Advanced Analytics
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='taPriceButton'>
                                <button onClick={this.handleStartToday}>Add Trivia</button>
                            </div>
                        </div>

                        {/* Enterprise */}
                        <div className='taPriceCard'>
                            <div className='taPriceType'>
                                <h5>Enterprise</h5>
                                <h3>Price on Request</h3>
                            </div>
                            <div className='taPriceDecription'>
                                <div className='taPriceSummary'>
                                    <div className='taPriceSummaryContext'>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            <b>Everything in Pro +</b>
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Activity Reports
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Custom Features
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Higher Uptime SLA
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Dedicated Account Manager
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Priority Customer Support
                                        </p>
                                        <p>
                                            <img src={check} data-src={check} width={14} height={14} alt='Check Icon' />
                                            Custom Data Retention Policy
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='taPriceButton'>
                                <button onClick={() => {}}>Contact Us</button>
                            </div>
                        </div>
                        <ReactTooltip
                            className='tooltip'
                            id='userTooltip'
                            place='top'
                            effect='solid'
                            arrowColor='#C9C9FA'
                        >
                            A user is anyone who interacts with Trivia. This includes actions like playing Games,
                            engaging in VirtualCoffee, submitting commands and visiting the dashboard.omizable Polls,
                            Pictionary, Gotcha!
                        </ReactTooltip>
                    </div>
                </div>
                <div className='slider'>
                    <Carousel
                        showArrows={false}
                        showStatus={false}
                        swipeable={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        showThumbs={false}
                    >
                        <div className='cardCtr'>
                            {/* Basic */}
                            <div className='taPriceCard'>
                                <div className='taPriceType'>
                                    <h5>Basic</h5>
                                    <h2>
                                        $0<span>/mo</span>
                                    </h2>
                                </div>
                                <div className='taPriceDecription'>
                                    <div className='taPriceSummary'>
                                        <div className='taPriceSummaryContext'>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Limited Games
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Limited Participation
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Game Scheduler
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Add Admins
                                                <span>New</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </div>
                        </div>

                        <div className='cardCtr'>
                            {/* Standard */}
                            <div className='taPriceCard'>
                                <div className='taPriceType'>
                                    <h5>Standard</h5>
                                    <h2>
                                        {this.props.standard}
                                        <span>/mo</span>
                                    </h2>
                                </div>
                                <div className='taPriceDecription'>
                                    <div className='taPriceSummary'>
                                        <div className='taPriceSummaryContext'>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Unlimited Games
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Unlimited Participation
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Custom Polls
                                                <span>New</span>
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Game Scheduler
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Add Admins
                                                <span>New</span>
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Quiz Leaderboard
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Control Game Hosts
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Limited Pictionary
                                                <span>New</span>
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Limited Water Cooler
                                                <span>New</span>
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Limited VirtualCoffee
                                                <span>New</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </div>
                        </div>

                        <div className='cardCtr'>
                            {/* Pro */}
                            <div className='taPriceCard'>
                                <div className='taPriceType'>
                                    <h5>Pro</h5>
                                    <h2>
                                        {this.props.pro}
                                        <span>/mo</span>
                                    </h2>
                                </div>
                                <div className='taPriceDecription'>
                                    <div className='taPriceSummary'>
                                        <div className='taPriceSummaryContext'>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Unlimited Trivia Quizzes
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Unlimited Games of Gotcha!
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Unlimited (Un)Popular Opinions
                                            </p>

                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Workspace Leaderboard
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Schedule Games
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Create Custom Quizzes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='taPriceButton'>
                                    <button onClick={this.handleStartToday}>Add Trivia</button>
                                </div>
                            </div>
                        </div>
                        <div className='cardCtr'>
                            {/* Enterprise */}
                            <div className='taPriceCard'>
                                <div className='taPriceType'>
                                    <h5>Enterprise</h5>
                                    <h3>Price on Request</h3>
                                </div>
                                <div className='taPriceDecription'>
                                    <div className='taPriceSummary'>
                                        <div className='taPriceSummaryContext'>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                <b>Everything in Pro</b>
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Workspace Activity Reports
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Custom Features
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Higher Uptime SLA
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Dedicated Account Manager
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Dedicated Customer Support
                                            </p>
                                            <p>
                                                <img
                                                    src={check}
                                                    data-src={check}
                                                    width={14}
                                                    height={14}
                                                    alt='Check Icon'
                                                />
                                                Custom Data Retention Policy
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='taPriceButton'>
                                    <button onClick={() => {}}>Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <StartModal
                    show={this.state.showModal}
                    hide={() => this.setState({ showModal: false })}
                    utm={this.state.utm}
                    slackAddUrl={process.env.GATSBY_TRIVIA_SLACK_ADD_URL}
                    teamsAddUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_URL}
                    slackLoginUrl={process.env.GATSBY_TRIVIA_SLACK_LOGIN_URL}
                    teamsLoginUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_LOGIN_URL}
                />
                <div className='email-us'>
                    <p>
                        Do you run a community workspace on Slack? Drop us a line at{' '}
                        <a href='mailto:trivia@springworks.in'>trivia@springworks.in</a> and we'll work out a special
                        deal for you.
                    </p>
                </div>
            </div>
        );
    }
}

export default Price;
